import { Observable } from 'rxjs';
import { createOperationObservable } from 'state-domains/domain';

import { buildValidationRulesUrl } from '../../../network/urls';
import { ValidationRule } from '../types';

export const addConfigurationValidationRule = (
    payload: Partial<ValidationRule>,
): Observable<ValidationRule> =>
    createOperationObservable('add', buildValidationRulesUrl, undefined, '', payload);
